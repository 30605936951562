<template>

    <div class="cakeshop-profile">

        <div class="mt-5">

            <el-row type="flex" align="middle">
                <el-col :span="8">
                </el-col>
                <el-col :span="8" class="text-center"><span class="uppercase-title">{{cakeshop.name}}</span></el-col>
                <el-col :span="8" class="text-right">
                    <div v-show="role === 'ADMIN'">
                        <span><svg-icon class="mr-1" icon-class="start"/>{{cakeshop.rate}}</span>
                        <span class="ml-4"><svg-icon class="mr-1" icon-class="phone"/>{{cakeshop.managerPhone}}</span>
                        <span class="ml-4"><svg-icon class="mr-1" icon-class="location"/>{{cakeshop.city.name}}</span>
                    </div>
                </el-col>
            </el-row>

        </div>

        <div class="mt-4 pt-4 pb-4 px-5 bg-white border border-gray rounded">

            <el-row class="mt-1" type="flex" align="middle">

                <el-col :span="8">
                    <div class="breadcrumbs" @click="goBack">
                        <svg-icon class="mr-1" icon-class="back-arrow"/>
                        Volver a listado de Creaciones
                    </div>
                </el-col>

                <el-col :span="10" class="text-center">
                    <span class="box-title">{{cake.name}}</span>
                </el-col>

                <el-col :span="8" class="d-flex flex-row justify-content-end">
                    <template v-if="editing">
                        <div>
                            <el-button type="text" :disabled="loading" @click="editing = false">
                                Cancelar
                            </el-button>
                        </div>

                        <div class="ml-2">
                            <el-button type="success" :loading="loading" :disabled="photosLoading" @click="requestCake">
                                Guardar
                            </el-button>
                        </div>
                    </template>

                    <template v-else-if="pricing">
                        <div>
                            <el-button type="text" :disabled="loading" @click="pricing = false">
                                Cancelar
                            </el-button>
                        </div>

                        <div class="ml-2">
                            <el-button type="success" :loading="loading" @click="requestCakePricing">
                                Guardar
                            </el-button>
                        </div>
                    </template>

                    <template v-else>
                        <div>
                            <el-button type="success" :disabled="!cake.uuid" @click="pricing = true">
                                Ver Precios<i class="ml-2 el-icon-right" style="font-weight: bold"></i>
                            </el-button>
                        </div>

                        <div class="ml-2">
                            <el-button :disabled="!cake.uuid" @click="editing = true">
                                Editar Creación
                            </el-button>
                        </div>
                    </template>
                </el-col>

            </el-row>

            <el-row class="mt-4">
                <el-col>

                    <cake-form
                        v-if="cake.uuid && editing"
                        :do-form-submit="doFormSubmit"
                        :initial-cake="cakeForm"
                        :initial-images="base64ImagesForm"
                        :images-loading="photosLoading"
                        @submit="saveCake"
                        @not-valid="resetRequest"/>

                    <cake-prices-form
                        v-else-if="cake.uuid && pricing"
                        :do-form-submit="doFormSubmit"
                        :initial-cake-customizations="customizationsForm"
                        :initial-cake-fillings="fillingsForm"
                        :initial-cake-flours="floursForm"
                        :initial-cake-toppings="toppingsForm"
                        :initial-cake-sweeteners="sweetenersForm"
                        :initial-cake-portions="portionsForm"
                        :initial-cake-shipping="shippingForm"
                        :initial-by-budget="cake.byBudget ? cake.byBudget : false"
                        :initial-allows-pay-on-delivery="cake.allowsPayOnDelivery ? cake.allowsPayOnDelivery : false"
                        :initial-available-for-pickup="cake.availableForPickup"
                        :initial-minimum-price="cake.minimumPrice ? parseInt(cake.minimumPrice) : null"
                        :purchasable="cake.purchasable"
                        @submit="saveCakePricing"
                        @not-valid="resetRequest"/>

                    <cake-detail v-else :cake="cake" @deleted="goBack()" @updated="refreshCake"/>

                </el-col>
            </el-row>

        </div>

    </div>

</template>

<script>
    import {messages} from '../../../constants/text-constants';
    import {mapGetters} from 'vuex';
    import {getCake, updateCake, setCakePrices} from "../../../api/cake-api";
    import CakeDetail from "./components/CakeDetail";
    import CakeForm from "../../../components/CakeForm";
    import CakePricesForm from "../../../components/CakePricesForm";
    import {getBase64Media} from '../../../api/media-api';

    export default {
        name: "CakeProfile",
        components: {
            CakePricesForm,
            CakeDetail,
            CakeForm
        },
        data() {
            return {
                pricing: false,
                editing: false,
                cakeshop: {
                    city: {}
                },
                cake: {},
                base64Images: [],
                doFormSubmit: false,
                loading: false,
                photosLoading: false,
                base64Requests: 0
            };
        },
        created() {
            this.loadCakeshop();
            this.loadCake();
        },
        computed: {
            ...mapGetters([
                'cakeshopById',
                'role'
            ]),
            cakeForm() {
                return {
                    purchasable: this.cake.purchasable,
                    name: this.cake.name,
                    description: this.cake.description,
                    processingTime: this.cake.processingTime,
                    preservation: this.cake.preservation,
                    recommendations: this.cake.recommendations,
                    dyes: this.cake.dyes,
                    allergens: (Array.isArray(this.cake.allergens) ? this.cake.allergens.map(allergen => allergen.uuid) : []),
                    allergenTraces: (Array.isArray(this.cake.allergenTraces) ? this.cake.allergenTraces.map(trace => trace.uuid) : []),
                    customizations: (Array.isArray(this.cake.customizations) ? this.cake.customizations.map(customization => customization) : []),
                    fillings: (Array.isArray(this.cake.fillings) ? this.cake.fillings.map(filling => filling) : []),
                    flours: (Array.isArray(this.cake.flours) ? this.cake.flours.map(flour => flour) : []),
                    toppings: (Array.isArray(this.cake.toppings) ? this.cake.toppings.map(topping => topping) : []),
                    sweeteners: (Array.isArray(this.cake.sweeteners) ? this.cake.sweeteners.map(sweetener => sweetener) : []),
                    ingredients: (Array.isArray(this.cake.ingredients) ? this.cake.ingredients.map(ingredient => ingredient) : []),
                    tags: (Array.isArray(this.cake.tags) ? this.cake.tags.map(tag => tag.uuid) : []),
                }
            },
            base64ImagesForm() {
                return Array.isArray(this.base64Images)
                    ? this.base64Images.map(image => image)
                    : []
            },
            customizationsForm() {
                return Array.isArray(this.cake.customizations)
                    ? this.cake.customizations.map(customization => customization)
                    : []
            },
            fillingsForm() {
                return Array.isArray(this.cake.fillings)
                    ? this.cake.fillings.map(filling => filling)
                    : []
            },
            floursForm() {
                return Array.isArray(this.cake.flours)
                    ? this.cake.flours.map(flour => flour)
                    : []
            },
            toppingsForm() {
                return Array.isArray(this.cake.toppings)
                    ? this.cake.toppings.map(topping => topping)
                    : []
            },
            sweetenersForm() {
              return Array.isArray(this.cake.sweeteners)
                  ? this.cake.sweeteners.map(sweetener => sweetener)
                  : []
            },
            portionsForm() {
                return Array.isArray(this.cake.portions)
                    ? this.cake.portions.map(portion => portion)
                    : []
            },
            shippingForm() {
                return {
                    type: this.cake.shippingType,
                    price: this.cake.shippingPrice
                }
            }
        },
        methods: {
            loadCakeshop() {
                this.cakeshop = Object.assign({}, this.cakeshopById(this.$route.params.cakeshopId));
            },
            loadCake() {
                getCake(this.$route.params.cakeId).then(cake => {
                    this.cake = this.parseCake(cake)
                });
            },
            parseCake(cake) {
                let parsedCake = Object.assign({}, cake)
                parsedCake.allergens = Array.isArray(parsedCake.allergensObjects)
                    ? parsedCake.allergensObjects.map(item => item)
                    : []
                parsedCake.allergenTraces = Array.isArray(parsedCake.allergenTracesObjects)
                    ? parsedCake.allergenTracesObjects.map(item => item)
                    : []
                parsedCake.tags = Array.isArray(parsedCake.tagsObjects)
                    ? parsedCake.tagsObjects.map(item => item)
                    : []
                return parsedCake
            },
            goBack() {
                this.$router.push({
                    name: 'cakeshop-profile',
                    params: {uuid: this.$route.params.cakeshopId, cakeshop: this.cakeshop}
                });
            },
            resetRequest() {
                this.doFormSubmit = false;
                this.loading = false;
            },
            requestCake() {
                this.doFormSubmit = true;
                this.loading = true;
            },
            saveCake(cake) {
                let request = {
                    "purchasable": cake.purchasable,
                    "name": cake.name,
                    "description": cake.description,
                    "processingTime": cake.processingTime,
                    "preservation": cake.preservation,
                    "recommendations": cake.recommendations,
                    "dyes": cake.dyes,
                    "allergens": cake.allergens,
                    "allergenTraces": cake.allergenTraces,
                    "customizations": cake.customizations,
                    "fillings": cake.fillings,
                    "flours": cake.flours,
                    "toppings": cake.toppings,
                    "sweeteners": cake.sweeteners,
                    "ingredients": cake.ingredients,
                    "photos": cake.base64Images,
                    "tags": cake.tags,
                    "rank": cake.rank,
                };

                updateCake(this.cake.uuid, request)
                    .then((response) => {
                        this.$message.success(messages.cake.update.success);
                        this.base64Images = cake.base64Images;
                        this.cake = this.parseCake(response);
                        this.editing = false;
                    })
                    .catch(() => {
                        this.$message.error(messages.cake.update.error);
                    })
                    .finally(() => {
                        this.resetRequest();
                    });
            },
            requestCakePricing() {
                this.doFormSubmit = true;
                this.loading = true;
            },
            saveCakePricing(cakePrices) {
                let request = {
                    "byBudget": cakePrices.byBudget,
                    "availableForPickup": cakePrices.availableForPickup,
                    "allowsPayOnDelivery": cakePrices.allowsPayOnDelivery,
                    "shippingType": cakePrices.shipping.type,
                    "shippingPrice": cakePrices.shipping.price,
                    "customizations": cakePrices.customization,
                    "fillings": cakePrices.fillings,
                    "flours": cakePrices.flours,
                    "toppings": cakePrices.toppings,
                    "sweeteners": cakePrices.sweeteners,
                    "portions": cakePrices.portions,
                    "minimumPrice": cakePrices.minimumPrice
                };

                setCakePrices(this.cake.uuid, request)
                    .then(() => {
                        this.$message.success(messages.cake.pricing.success);
                        this.pricing = false;
                        this.cake.byBudget = cakePrices.byBudget;
                        this.cake.availableForPickup = cakePrices.availableForPickup;
                        this.cake.allowsPayOnDelivery = cakePrices.allowsPayOnDelivery;
                        this.cake.shippingType = cakePrices.shipping.type;
                        this.cake.shippingPrice = cakePrices.shipping.price;
                        this.cake.customizations = cakePrices.customization.map(customization => customization);
                        this.cake.portions = cakePrices.portions.map(portion => portion);
                        this.cake.minimumPrice = cakePrices.minimumPrice;
                    })
                    .catch(() => {
                        this.$message.error(messages.cake.pricing.error);
                    })
                    .finally(() => {
                        this.resetRequest();
                    });
            },
            refreshCake(cake) {
                this.cake = Object.assign({}, cake);
            }
        },
        watch: {
            cake: function () {
                // Fill array with base64 images
                if ((!Array.isArray(this.base64Images) || this.base64Images.length === 0) && Array.isArray(this.cake.photos) && this.cake.photos.length) {
                    this.photosLoading = true;
                    this.base64Requests = 0;
                    this.cake.photos.forEach(photo => {
                        if (photo.name !== 'placeholder') {
                            getBase64Media(photo.uuid)
                                .then(response => {
                                    this.base64Images.push(response.base64);
                                })
                                .finally(() => {
                                    this.base64Requests = this.base64Requests + 1;
                                    if (this.base64Requests === this.cake.photos.length) {
                                        this.photosLoading = false;
                                    }
                                });
                        } else {
                            this.photosLoading = false;
                        }
                    });
                }
            }
        }
    }
</script>

<template>
    <div v-loading="loading">
        <el-row :gutter="20">
            <el-col :span="8">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            DESCRIPCIÓN GENERAL
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Nombre
                        </label>
                        <div>{{cake.name}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Descripción
                        </label>
                        <div>{{cake.description}}</div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Plazo de elaboración
                        </label>
                        <div v-if="cake.processingTime">
                            {{cake.processingTime}}
                            <template v-if="parseInt(cake.processingTime) === 1">día</template>
                            <template v-else>días</template>
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Conservación y caducidad
                        </label>
                        <div>{{cake.preservation}}</div>
                    </el-col>
                </el-row>

                <el-row v-if="cake.recommendations" class="mt-4">
                    <el-col>
                        <label>
                            Recomendaciones
                        </label>
                        <div>{{cake.recommendations}}</div>
                    </el-col>
                </el-row>

                <el-row v-show="cake.dyes" class="mt-4">
                    <el-col>
                        <label>
                            Colorantes y conservantes
                        </label>
                        <div>{{cake.dyes}}</div>
                    </el-col>
                </el-row>


                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Alérgenos
                        </label>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col v-if="Array.isArray(cake.allergens) && cake.allergens.length" :span="12">
                        <label>
                            <span class="ml-2 allergen-sublabel">CONTIENE</span>
                        </label>
                        <div
                            :class="['ml-2', {'mt-1': index!==0}]"
                            v-for="(allergen, index) in cake.allergens"
                            :key="allergen.uuid">{{allergen.name}}
                        </div>
                    </el-col>

                    <el-col v-if="Array.isArray(cake.allergenTraces) && cake.allergenTraces.length" :span="12">
                        <label>
                            <span class="ml-2 allergen-sublabel">TRAZAS DE</span>
                        </label>
                        <div
                            :class="['ml-2', {'mt-1': index!==0}]"
                            v-for="(trace, index) in cake.allergenTraces"
                            :key="trace.uuid">{{trace.name}}
                        </div>
                    </el-col>
                </el-row>

                <el-row v-if="cake.tags" class="mt-4">
                    <el-col>
                        <label>
                            Tags
                        </label>
                        <div class="ml-2 mt-1" v-for="tag in cake.tags" :key="tag.uuid">{{tag.name}}</div>
                    </el-col>
                </el-row>

            </el-col>

            <el-col :span="8">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            PERSONALIZACIÓN E INGREDIENTES
                        </div>
                    </el-col>
                </el-row>

                <el-row v-if="Array.isArray(cake.customizations) && cake.customizations.length" class="mt-4">
                    <el-col>
                        <label>
                            Extras
                        </label>
                        <div
                            class="ml-2 mt-1"
                            v-for="customization in cake.customizations"
                            :key="customization.name">{{customization.name}}
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-4">
                    <el-col>
                        <label>
                            Ingredientes
                        </label>
                        <div
                            class="ml-2 mt-1"
                            v-for="ingredient in cake.ingredients"
                            :key="ingredient">{{ingredient}}
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-4" v-show="cake.fillings && cake.fillings.length > 0">
                    <el-col>
                        <label>
                            Rellenos
                        </label>
                        <div
                            class="ml-2 mt-1"
                            v-for="filling in cake.fillings"
                            :key="filling.name">{{filling.name}}
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-4" v-show="cake.flours && cake.flours.length > 0">
                    <el-col>
                        <label>
                            Harinas - Bizcochos
                        </label>
                        <div
                            class="ml-2 mt-1"
                            v-for="flour in cake.flours"
                            :key="flour.name">{{flour.name}}
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-4" v-show="cake.toppings && cake.toppings.length > 0">
                    <el-col>
                        <label>
                            Coberturas
                        </label>
                        <div
                            class="ml-2 mt-1"
                            v-for="topping in cake.toppings"
                            :key="topping.name">{{topping.name}}
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-4" v-show="cake.sweeteners && cake.sweeteners.length > 0">
                  <el-col>
                    <label>
                      Endulzantes
                    </label>
                    <div
                        class="ml-2 mt-1"
                        v-for="sweetener in cake.sweeteners"
                        :key="sweetener.name">{{sweetener.name}}
                    </div>
                  </el-col>
                </el-row>

            </el-col>

            <el-col :span="8">
                <el-row>
                    <el-col>
                        <div class="section-title">
                            IMAGEN DESTACADA
                        </div>
                    </el-col>
                </el-row>

                <el-row class="mt-3">
                    <el-col>
                        <image-slider :image-urls="cakeImagesUrls"></image-slider>
                    </el-col>
                </el-row>

                <el-row v-show="role === 'ADMIN'" class="mt-5 py-1" type="flex" align="middle">
                    <el-col>
                        <el-switch
                            class=""
                            :value="cake.status === 'PUBLISHED'"
                            @change="changeCakeStatus"/>
                        <span v-if="cake.status === 'PUBLISHED'" class="pt-1 ml-2">Creación publicada</span>
                        <span v-else class="pt-1 ml-2">Creación no publicada</span>
                    </el-col>
                </el-row>

                <el-row v-show="role === 'ADMIN'" class="mt-3">
                    <el-col class="cursor-pointer">
                        <div @click="confirmDeleteCake">
                            <svg-icon icon-class="delete"/>
                            Eliminar Creación
                        </div>
                    </el-col>
                </el-row>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {messages, dialogs} from '../../../../constants/text-constants';
    import {mapGetters} from 'vuex';
    import {activateCake, deleteCake, disableCake} from "@/api/cake-api";
    import ImageSlider from "../../../../components/ImageSlider";

    export default {
        name: "CakeDetail",
        components: {
            ImageSlider
        },
        props: {
            cake: Object
        },
        data() {
            return {
                loading: false
            };
        },
        computed: {
            cakeImagesUrls() {
                return Array.isArray(this.cake.photos)
                    ? this.cake.photos.filter(photo => photo.name !== 'placeholder').map(photo => photo.url)
                    : [];
            },
            ...mapGetters([
                'role',
            ]),
        },
        methods: {
            changeCakeStatus(checked) {
                if (checked) {
                    this.enableCake();
                } else {
                    this.disableCake();
                }
            },
            async enableCake() {

                this.$confirm(dialogs.cake.enable.text, {
                    confirmButtonText: dialogs.cake.enable.confirm,
                    cancelButtonText: dialogs.cake.enable.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {

                    this.loading = true;
                    activateCake(this.cake.uuid).then(() => {
                        this.cake.status = 'PUBLISHED';
                        this.loading = false;
                        this.showMessageChangeCakeStatusSuccess();
                    }).catch(() => {
                        this.loading = false;
                        this.showMessageChangeCakeStatusError();
                    });

                }).catch(() => {
                });
            },
            async disableCake() {

                this.$confirm(dialogs.cake.disable.text, {
                    confirmButtonText: dialogs.cake.disable.confirm,
                    cancelButtonText: dialogs.cake.disable.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {

                    this.loading = true;
                    disableCake(this.cake.uuid).then((cake) => {
                        this.$emit('updated', cake);
                        this.loading = false;
                        this.showMessageChangeCakeStatusSuccess();
                    }).catch(() => {
                        this.loading = false;
                        this.showMessageChangeCakeStatusError();
                    });

                }).catch(() => {
                });

            },
            showMessageChangeCakeStatusSuccess() {
                this.$message.success(messages.cake.status.success);
            },
            showMessageChangeCakeStatusError() {
                this.$message.error(messages.cake.status.error);
            },
            confirmDeleteCake() {
                this.$confirm(dialogs.cake.cancel.text, {
                    confirmButtonText: dialogs.cake.cancel.confirm,
                    cancelButtonText: dialogs.cake.cancel.cancel,
                    dangerouslyUseHTMLString: true,
                }).then(() => {
                    this.deleteCake();
                }).catch(() => {
                });
            },
            deleteCake() {
                this.loading = true;
                deleteCake(this.cake.uuid).then(() => {
                    this.loading = false;
                    this.$message.success(messages.cake.delete.success);
                    this.$emit('deleted');
                }).catch(() => {
                    this.loading = false;
                    this.$message.error(messages.cake.delete.error);
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .cursor-pointer :hover {
        font-weight: bold;

        .svg-icon {
            width: 1.2em;
            height: 1.2em;
        }
    }

    .allergen-sublabel {
        font-size: 14px;
        font-weight: normal;
        color: #000000;
    }
</style>
